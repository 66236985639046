import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NextHire from './elements/NextHire';
import StrategicRoles from './elements/StrategicRoles';
import RecordOfSuccess from './elements/RecordOfSuccess';
import YourSuccessIsOurSuccess from './elements/YourSuccessIsOurSuccess';
import TestimonialCarousel from './elements/TestimonialCarousel';
import WantToLearnMore from './elements/WantToLearnMore';
import EmployerHeroBanner from './elements/EmployerHeroBanner';
import PricingPlan from './elements/PricingPlan';

const EmployerIndex: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool</title>
          <meta
            name="description"
            content="Hiperpool's proven approach to recruitment expertly blends time-tested executive search methods with our dynamic online community of pre-vetted talent. This powerful combination ensures a consistent pipeline of highly engaged candidates for strategic roles in consulting, strategy, and investing. Our strategy excels in accessing a diverse talent pool, reaching both active job seekers and passive candidates. Choose Hiperpool for a reliable, effective solution in filling your critical vacancies with top-tier professionals."
          />
        </Helmet>

        <EmployerHeroBanner />
        <RecordOfSuccess />
        <NextHire />
        <StrategicRoles />
        <PricingPlan />
        <TestimonialCarousel />
        <YourSuccessIsOurSuccess />
        <WantToLearnMore />
      </HelmetProvider>
    </>
  );
};

export default EmployerIndex;
