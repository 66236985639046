import React, { FC } from 'react';
import { Button, CircleGraphic } from '#components/Index';
import CareerWoman from '#assets/homepage/careerWoman.webp';
import CareerMan from '#assets/homepage/careerMan.webp';
import HeroBanner from '#components/HeroBanner/HeroBanner';
import { paths } from '#pages/paths';

const HeroBannerImageContent = () => (
  <>
    <div className="flex flex-col sm:justify-end sm:max-w-[18rem] sm:w-[calc(50%-1.25rem)]">
      <img
        loading="eager"
        src={CareerWoman}
        alt="woman in blue tank top standing beside white wall"
        height="auto"
        width="100%"
        className="rounded-full max-sm:aspect-square object-cover object-top"
      />

      <CircleGraphic className="max-sm:hidden w-full h-auto">
        <CircleGraphic.Half x={2} color="green-900" rotate={180} />
      </CircleGraphic>
    </div>

    <div className="relative sm:max-w-[18rem] sm:w-[calc(50%-1.25rem)]">
      <CircleGraphic cols={2} className="w-full h-auto">
        <CircleGraphic.Half color="green-500" />
      </CircleGraphic>
      <img
        loading="eager"
        src={CareerMan}
        alt="man in suit sitting in a chair"
        height="auto"
        width="100%"
        className="rounded-full max-sm:rounded-t-none max-sm:aspect-square object-cover object-[50%_25%]"
      />
    </div>
  </>
);

const HeroBannerTextContent = () => (
  <>
    <div className="text-display-md font-serif lg:text-display-lg xl:text-display-xl">
      Find Experienced Professionals for mission critical roles<span className="text-green-500">.</span>
    </div>
    <div className="my-7 sm:my-10 lg:text-xl max-w-lg">
      Hiperpool can help you fill tough to hire strategic roles – quickly, simply and affordably – thanks to our
      community of highly engaged consulting, strategy, and finance professionals
    </div>
    <Button to={paths.signUpEmployer()} size="large">
      Get in Touch
    </Button>
  </>
);

const HeroBannerBgContent = () => (
  <CircleGraphic className="absolute right-0 bottom-0 w-variable-lg translate-y-1/3 min-w-[8rem] max-w-[12rem] h-auto">
    <CircleGraphic.Quarter x={2} color="hpblack" rotate={90} />
    <CircleGraphic.Quarter x={3} color="green-900" rotate={180} />
    <CircleGraphic.Half x={2} y={2} color="green-900" rotate={180} />
    <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={-90} />
    <CircleGraphic.Circle x={2} y={3} color="green-500" radius={0.5} />
    <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={180} />
  </CircleGraphic>
);

const EmployerHeroBanner: FC = () => (
  <HeroBanner
    imageContent={HeroBannerImageContent()}
    textContent={HeroBannerTextContent()}
    bgContent={HeroBannerBgContent()}
    direction="rtl"
    colorScheme="dark"
  />
);

export default EmployerHeroBanner;
