import React, { FC } from 'react';
import DirectorOfStrategyPNG from '#assets/homepage/directorOfStrategy.png';
import InviteToInterviewPNG from '#assets/homepage/inviteToInterview.png';
import PositionFilledPNG from '#assets/homepage/positionFilled.png';
import PanelTextSection from '../../elements/PanelTextSection';

const NextHire: FC = () => {
  return (
    <div className="bg-gray-50 pt-16">
      <h2 className={`text-display-md font-serif text-center px-variable-md`}>
        How to make your next hire with Hiperpool
      </h2>
      <div>
        <PanelTextSection
          indicatorIcon={
            <div className="grid place-items-center w-10 h-10 font-serif text-white text-display-sm rounded-full bg-green-900">
              1
            </div>
          }
          header="Tell us about your vacancy"
          description="A dedicated team member takes the time to understand your role and what you’re looking for. We’ll advise on everything from the job description, the perfect candidate profile, and compensation."
          image={
            <img
              loading="lazy"
              src={DirectorOfStrategyPNG}
              alt="matching profile: woman wearing grey striped dress shirt sitting down near brown wooden table in front of white laptop computer"
              width="330"
              height="278"
            />
          }
        />
        <PanelTextSection
          className="bg-white sm:rtl"
          indicatorIcon={
            <div className="grid place-items-center w-10 h-10 font-serif text-white text-display-sm rounded-full bg-green-700">
              2
            </div>
          }
          header="We’ll identify and screen the best candidates"
          description="Our team of specialist recruiters will identify and screen the best candidates for your role. We’ll work closely with you to ensure you’re always receiving the right candidates."
          image={
            <img
              loading="lazy"
              src={InviteToInterviewPNG}
              alt="two candidates: woman in yellow blazer and blue denim jeans smiling and man in blue shirt sitting down"
              width="420"
              height="274"
            />
          }
        />
        <PanelTextSection
          indicatorIcon={
            <div className="grid place-items-center w-10 h-10 font-serif text-white text-display-sm rounded-full bg-green-900">
              3
            </div>
          }
          header="Hire only the best"
          description="Take candidates through your interview process, and hire your next team member! A dedicated team member is there to support you throughout the process."
          image={
            <img
              loading="lazy"
              src={PositionFilledPNG}
              alt="position filled: woman in gray sweater standing"
              width="320"
              height="240"
            />
          }
        />
      </div>
    </div>
  );
};

export default NextHire;
