import React, { FC } from 'react';
import { Navbar, NavItem, AccountButtonItem } from '#components/Navbar/Navbar';
import { Button } from '#components/Index';
import { paths } from '#pages/paths';
import { useUserDetails } from '#src/AppData';

// Unsigned user navigation items
export const ProspectNavItems = () => (
  <>
    <NavItem to={paths.stories()}>Success Stories</NavItem>
    <NavItem to={paths.candidates()}>For Professionals</NavItem>
  </>
);

export const EmployerNavItems = () => (
  <>
    <NavItem to={paths.employerDashboard()}>Dashboard</NavItem>
    <NavItem to={paths.employerPotentialCandidates()}>Talent Pool</NavItem>
    <NavItem to={paths.employerInterviews()}>Interviews</NavItem>
    <NavItem to={paths.employerReferrals()}>Referrals</NavItem>
  </>
);

export const ProspectButtonItems = () => (
  <>
    <Button variant="link" size="small" navTo={paths.signIn()}>
      Sign in
    </Button>
    <Button variant="primary" size="small" navTo={paths.signUpEmployer()}>
      {`Let's talk`}
    </Button>
  </>
);

export const SignedInButtonItems = () => (
  <>
    <div className="max-xl:hidden">
      <Button variant="link" size="small" navTo={paths.candidates()}>
        For Professionals
      </Button>
    </div>
    <AccountButtonItem />
  </>
);

export const EmployerNavbar: FC = () => {
  const { user, isLoggedIn } = useUserDetails();

  return (
    <Navbar
      testid="employer-navbar"
      navItems={isLoggedIn && user?.type === 'Employer' ? <EmployerNavItems /> : <ProspectNavItems />}
      buttonItems={isLoggedIn ? <SignedInButtonItems /> : <ProspectButtonItems />}
    />
  );
};
