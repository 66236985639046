import React, { FC } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { TextInput, PhoneInputRHF, Success } from '#components/Form/Index';
import { Button, ErrorAlert, Icon } from '#components/Index';
import { useSignUpController } from './FormController';
import { paths, parseParamsToLinkSearch, signInRedirectUrl } from '#pages/paths';

const SignUpForm: FC = () => {
  const [searchParams] = useSearchParams();
  const redirectUrlFromQueryParams = searchParams.get('redirectUrl');

  const { form, submitForm, baseErrors } = useSignUpController();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = form;

  if (isSubmitSuccessful) {
    return (
      <Success title="Thank you for reaching out." icon={<Icon.MailCheckLine size={6} />}>
        <div className="rich-text">
          <p>Your request has been submitted successfully. A member of our team will be in touch with you shortly.</p>
        </div>
        <div className="mt-8">
          <Button to={paths.employers()} size="large">
            Back to homepage
          </Button>
        </div>
      </Success>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitForm)} className="w-full grid gap-6 text-sm text-gray-700 font-medium">
      <div className="grid sm:grid-cols-2 gap-6">
        <TextInput
          required
          label="First name"
          type="text"
          error={errors.firstName?.message}
          {...register('firstName')}
        />
        <TextInput required label="Last name" type="text" error={errors.lastName?.message} {...register('lastName')} />
      </div>
      <TextInput
        required
        label="Company"
        type="text"
        error={errors.companyName?.message}
        {...register('companyName')}
      />
      <TextInput required label="Job title" type="text" error={errors.position?.message} {...register('position')} />
      <PhoneInputRHF name="phone" label="Phone" error={errors.phone?.message} control={form.control} />
      <TextInput required label="Work email" type="email" error={errors.email?.message} {...register('email')} />
      <p>
        By signing up, I agree to Hiperpool’s{' '}
        <Link to={paths.terms()} className="link">
          Terms
        </Link>
        ,{' '}
        <Link to={paths.cookies()} className="link">
          Cookies
        </Link>{' '}
        and{' '}
        <Link to={paths.privacy()} className="link">
          Privacy Policy
        </Link>
        .
      </p>
      <ErrorAlert text={baseErrors} />
      <Button type="submit" className="xs:mx-auto" size="large">
        Get in touch
      </Button>
      <p className="text-center">
        If you are already a member of Hiperpool{' '}
        <Link
          to={paths.signIn() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })}
          className="link"
        >
          Sign in here
        </Link>
      </p>
    </form>
  );
};

export default SignUpForm;
