import React, { FC } from 'react';

const StayInformed: FC = () => (
  <div className="py-24 px-variable-lg">
    <div className="max-w-8xl mx-auto">
      <dl className="max-w-[73rem] grid lg:flex gap-x-16 2xl:gap-x-18 gap-y-8 xl:pr-16 max-sm:mr-7">
        {[
          {
            title: 'First-hand insights',
            description: 'Exclusive guides and advice on industries and roles, from professionals who have been there.',
          },
          {
            title: 'Hand-picked roles',
            description:
              'Only relevant opportunities at innovative startups, private equity, consulting and established corporates looking to fill mission-critical roles.',
          },
          {
            title: 'Expert interview prep',
            description:
              'Read first-hand insights and connect with experienced coaches to build your confidence for interviews.',
          },
        ].map((text) => (
          <div key={text.title}>
            <dt className="font-serif text-display-xs mb-4">{text.title}</dt>
            <dd>{text.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
);

export default StayInformed;
