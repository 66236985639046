import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, ScrollRestoration, Outlet, redirect } from 'react-router-dom';

import { ROUTES } from '#pages/paths';
import { ErrorPage } from '#pages/root/ErrorPage';
import { useGetUserDetails, UserContext } from './AppData';
import { ErrorBoundary } from '@appsignal/react';
import appsignal from '#src/appsignal';

import MainLayout from '#pages/homepage/candidate/CandidateLayout';
import JobsOnboardingLayout from '#pages/jobsOnboarding/Layout';
import EmployerLayout from '#pages/homepage/employer/EmployerLayout';
import TalentLayout from '#pages/talentPages/TalentLayout';

// Public pages
import JobsOnboarding from '#pages/jobsOnboarding/JobsOnboarding';
import CandidateIndex from '#pages/homepage/candidate/Index';
import EmployerIndex from '#pages/homepage/employer/Index';
import Jobs from '#pages/homepage/jobs/Index';
import InterviewPrepSite from '#pages/homepage/interview/InterviewPrepSite';
import SignUpCandidate from '#pages/signUp/candidate/SignUp';
import SignUpEmployer from '#pages/signUp/employer/SignUp';
import SignIn from '#pages/signIn/SignIn';
import ContactUs from '#pages/contactUs/ContactUs';
import SupportForEmployer from '#pages/support/SupportForEmployer';
import SupportForCandidate from '#pages/support/SupportForCandidate';
import About from '#pages/about/About';
import SuccessStories from '#pages/successStories/index/SuccessStories';
import Story from '#pages/successStories/show/Story';
import InsightsAndResources from '#pages/blog/index/InsightsAndResources';
import NotFound from '#pages/NotFound';
import Cookies from '#pages/legal/cookies/Cookies';
import Privacy from '#pages/legal/privacy/Privacy';
import Terms from '#pages/legal/terms/Terms';
import BlogPost from '#pages/blog/show/Post';
import ResetPassword from '#pages/password/resetPassword/ResetPassword';
import UpdatePassword from '#pages/password/updatePassword/UpdatePassword';
import Coach from '#pages/coaches/show/Coach';
import Coaches from '#pages/coaches/index/Coaches';
import SignUpEmployerTeamMember from '#pages/signUp/employerTeamMember/EmployerTeamMember';
import ActivateAccount from '#pages/password/activateAccount/ActivateAccount';

// Talent pages
import Opportunities from '#pages/talentPages/opportunities/index/Index';
import OpportunityDetails from '#pages/talentPages/opportunities/show/OpportunityDetails';
import MyActivityPage from '#pages/talentPages/activity/MyActivityPage';
import Profile from '#pages/talentPages/profileAndPreferences/profile/Profile';
import Preferences from '#pages/talentPages/profileAndPreferences/preferences/Preferences';
import Settings from '#pages/talentPages/settings/Settings';
import EmployerViewProfile from '#pages/talentPages/profileAndPreferences/profile/EmployerViewProfile';
import Hiring from '#pages/talentPages/hiring/Hiring';

// Employer pages
import CandidatePreview from '#pages/employerPages/candidatePreview/CandidatePreview';
import EmployerDashboard from '#pages/employerPages/dashboard/Dashboard';
import PotentialCandidates from '#root/src/pages/employerPages/potentialCandidates/PotentialCandidates';
import Interviews from '#pages/employerPages/interviews/Interviews';
import Referrals from '#pages/employerPages/referrals/Referrals';
import TeamSettings from '#pages/employerPages/teamSettings/TeamSettings';
import AccountSettings from '#pages/employerPages/accountSettings/AccountSettings';
import EmployerOpportunity from '#pages/employerPages/opportunities/EmployerOpportunity';

// Shared pages
import OpportunityOverview from '#pages/opportunityOverview/OpportunityOverview';

const AppWrapper = () => {
  const { data } = useGetUserDetails();

  useEffect(function scrollToAnchor() {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to find it
      setTimeout(() => {
        document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }, []);

  useEffect(function calculateScrollBarWidth() {
    function calculateScrollbarWidth() {
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${window.innerWidth - document.documentElement.clientWidth}px`
      );
    }
    calculateScrollbarWidth();
    // recalculate on resize
    window.addEventListener('resize', calculateScrollbarWidth, false);
    // recalculate on load (assets loaded as well)
    window.addEventListener('load', calculateScrollbarWidth);

    return () => {
      window.removeEventListener('resize', calculateScrollbarWidth, false);
      window.removeEventListener('load', calculateScrollbarWidth);
    };
  });

  const context: UserContext = { user: data?.user, isLoggedIn: data?.user != null };

  return (
    <>
      <ScrollRestoration />
      <ErrorBoundary instance={appsignal} fallback={(error: any) => <ErrorPage error={error} />}>
        <Outlet context={context} />
      </ErrorBoundary>
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppWrapper />,
    children: [
      {
        path: ROUTES.root,
        element: <EmployerLayout withFooter />,
        children: [{ index: true, element: <EmployerIndex /> }],
      },
      {
        path: ROUTES.candidates,
        element: <MainLayout />,
        children: [{ index: true, element: <CandidateIndex /> }],
      },
      {
        path: ROUTES.employers,
        element: <EmployerLayout withFooter />,
        children: [{ index: true, element: <EmployerIndex /> }],
      },
      {
        path: ROUTES.jobs,
        element: <MainLayout />,
        children: [{ index: true, element: <Jobs /> }],
      },
      {
        path: ROUTES.interviewPrep,
        element: <MainLayout />,
        children: [{ index: true, element: <InterviewPrepSite /> }],
      },
      {
        path: ROUTES.coaches,
        element: <MainLayout />,
        children: [{ index: true, element: <Coaches /> }],
      },
      {
        path: ROUTES.about,
        element: <MainLayout />,
        children: [{ index: true, element: <About /> }],
      },
      {
        path: ROUTES.signUp,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <SignUpCandidate /> }],
      },
      {
        path: ROUTES.signUpEmployer,
        element: <EmployerLayout />,
        children: [{ index: true, element: <SignUpEmployer /> }],
      },
      {
        path: ROUTES.signUpEmployerTeamMember,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <SignUpEmployerTeamMember /> }],
      },
      {
        path: ROUTES.signIn,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <SignIn /> }],
      },
      {
        path: ROUTES.resetPassword,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <ResetPassword /> }],
      },
      {
        path: ROUTES.activateAccount,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <ActivateAccount /> }],
      },
      {
        path: ROUTES.updatePassword,
        element: <MainLayout withFooter={false} />,
        children: [{ index: true, element: <UpdatePassword /> }],
      },
      {
        path: ROUTES.contactUs,
        element: <MainLayout />,
        children: [{ index: true, element: <ContactUs /> }],
      },
      {
        path: ROUTES.jobsOnboarding,
        element: <JobsOnboardingLayout />,
        children: [{ index: true, element: <JobsOnboarding /> }],
      },
      {
        path: ROUTES.supportForEmployer,
        element: <MainLayout />,
        children: [{ index: true, element: <SupportForEmployer /> }],
      },
      {
        path: ROUTES.supportForCandidate,
        element: <MainLayout />,
        children: [{ index: true, element: <SupportForCandidate /> }],
      },
      {
        path: ROUTES.stories,
        element: <MainLayout />,
        children: [{ index: true, element: <SuccessStories /> }],
      },
      {
        path: ROUTES.story,
        element: <MainLayout />,
        children: [{ index: true, element: <Story /> }],
      },
      {
        path: ROUTES.blog,
        element: <MainLayout />,
        children: [{ index: true, element: <InsightsAndResources /> }],
      },
      {
        path: ROUTES.cookies,
        element: <MainLayout />,
        children: [{ index: true, element: <Cookies /> }],
      },
      {
        path: ROUTES.privacy,
        element: <MainLayout />,
        children: [{ index: true, element: <Privacy /> }],
      },
      {
        path: ROUTES.terms,
        element: <MainLayout />,
        children: [{ index: true, element: <Terms /> }],
      },
      {
        path: ROUTES.blogPost,
        element: <MainLayout />,
        children: [{ index: true, element: <BlogPost /> }],
      },
      {
        path: ROUTES.coach,
        element: <MainLayout />,
        children: [{ index: true, element: <Coach /> }],
      },
      {
        path: ROUTES.talentOpportunities,
        element: <TalentLayout />,
        children: [{ index: true, element: <Opportunities /> }],
      },
      {
        path: ROUTES.talentOpportunity,
        element: <TalentLayout />,
        children: [{ index: true, element: <OpportunityDetails /> }],
      },
      {
        path: ROUTES.opportunityOverview,
        element: <EmployerLayout withFooter />,
        children: [{ index: true, element: <OpportunityOverview /> }],
      },
      {
        path: ROUTES.employerCandidatePreview,
        element: <EmployerLayout />,
        children: [{ index: true, element: <CandidatePreview /> }],
      },
      {
        path: ROUTES.employerDashboard,
        element: <EmployerLayout withFooter />,
        children: [{ index: true, element: <EmployerDashboard /> }],
      },
      {
        path: ROUTES.employerPotentialCandidates,
        element: <EmployerLayout />,
        children: [{ index: true, element: <PotentialCandidates /> }],
      },
      {
        path: ROUTES.employerInterviews,
        element: <EmployerLayout className="bg-gray-50" />,
        children: [{ index: true, element: <Interviews /> }],
      },
      {
        path: ROUTES.employerReferrals,
        element: <EmployerLayout className="bg-gray-50" withFooter />,
        children: [{ index: true, element: <Referrals /> }],
      },
      {
        path: ROUTES.employerTeamSettings,
        element: <EmployerLayout />,
        children: [{ index: true, element: <TeamSettings /> }],
      },
      {
        path: ROUTES.employerAccountSettings,
        element: <EmployerLayout />,
        children: [{ index: true, element: <AccountSettings /> }],
      },
      {
        path: ROUTES.employerOpportunity,
        element: <EmployerLayout />,
        children: [{ index: true, element: <EmployerOpportunity /> }],
      },
      {
        path: ROUTES.talentActivity,
        element: <TalentLayout />,
        children: [{ index: true, element: <MyActivityPage /> }],
      },
      {
        path: ROUTES.talentProfile,
        element: <TalentLayout />,
        children: [{ index: true, element: <Profile /> }],
      },
      {
        path: ROUTES.talentPreferences,
        element: <TalentLayout />,
        children: [{ index: true, element: <Preferences /> }],
      },
      {
        path: ROUTES.talentSettings,
        element: <TalentLayout />,
        children: [{ index: true, element: <Settings /> }],
      },
      {
        path: ROUTES.talentProfileEmployerView,
        element: <TalentLayout />,
        children: [{ index: true, element: <EmployerViewProfile /> }],
      },
      {
        path: ROUTES.talentHiring,
        element: <TalentLayout />,
        children: [{ index: true, element: <Hiring /> }],
      },
      {
        path: '*',
        loader: async function redirectUnderscoreToHyphen({ params, request }) {
          let pathname: string = params['*'] ?? '';
          pathname = pathname.startsWith('/') ? pathname : '/' + pathname;
          const urlTail = request.url.split(pathname)[1];
          let replacedPathName = pathname.replace(/_/g, '-');
          replacedPathName = replacedPathName.startsWith('/') ? replacedPathName : '/' + replacedPathName;
          const routes: string[] = Object.values(ROUTES);
          if (routes.includes(replacedPathName)) {
            replacedPathName = replacedPathName.concat(urlTail);
            return redirect(replacedPathName);
          }
          return {};
        },
        element: <MainLayout />,
        children: [{ path: '*', element: <NotFound />, index: true }],
      },
    ],
  },
]);

export const AppRoutes = () => <RouterProvider router={router} />;
