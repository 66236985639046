import React, { FC, ReactNode } from 'react';
import { CircleGraphic } from '#components/Index';

interface StrategicRoleItemProps {
  circleGraphic: ReactNode;
  title: string;
  details: string[];
}

const LearnMoreItem: FC<StrategicRoleItemProps> = ({ circleGraphic, title, details }) => (
  <div>
    {circleGraphic}
    <dl>
      <dt className="font-serif text-display-xs mt-5 mb-4">{title}</dt>
      {details.map((detail, index) => (
        <dd key={index} className="my-2 leading-tight">
          {detail}
        </dd>
      ))}
      <dd className="text-gray-500">and more…</dd>
    </dl>
  </div>
);

const StrategicRoles: FC = () => {
  return (
    <div className="py-16 px-variable-sm text-center">
      <h2 className="text-display-md font-serif max-w-xl mx-auto">
        We specialize in strategic roles across industries
      </h2>
      <div className="mx-auto mt-14 max-w-5xl grid xs:grid-cols-2 md:grid-cols-4 gap-y-12 md:gap-x-4">
        <LearnMoreItem
          circleGraphic={
            <CircleGraphic className="w-12 h-12 mx-auto">
              <CircleGraphic.Quarter color="green-500" rotate={180} />
              <CircleGraphic.Quarter color="green-900" x={2} rotate={90} />
              <CircleGraphic.Quarter color="green-500" y={2} />
              <CircleGraphic.Quarter color="hpblack" x={2} y={2} rotate={-90} />
            </CircleGraphic>
          }
          title="Startups & Scaleups"
          details={['Strategy', 'Business Operations', 'Strategic Finance', 'Chief of Staff']}
        />
        <LearnMoreItem
          circleGraphic={
            <CircleGraphic className="w-12 h-12 mx-auto">
              <CircleGraphic.Quarter color="hpblack" rotate={90} />
              <CircleGraphic.Circle color="green-500" x={2} radius={0.5} />
              <CircleGraphic.Quarter color="green-900" y={2} />
              <CircleGraphic.Quarter color="hpblack" x={2} y={2} rotate={-90} />
            </CircleGraphic>
          }
          title="Consulting"
          details={['Strategy', 'Restructuring & Turnarounds', 'M&A / Due Diligence', 'Digital & AI']}
        />
        <LearnMoreItem
          circleGraphic={
            <CircleGraphic className="w-12 h-12 mx-auto">
              <CircleGraphic.Quarter rotate={90} color="green-500" />
              <CircleGraphic.Quarter rotate={90} color="green-900" x={2} />
              <CircleGraphic.Quarter rotate={90} color="green-500" y={2} />
              <CircleGraphic.Quarter rotate={90} color="hpblack" x={2} y={2} />
            </CircleGraphic>
          }
          title="Corporate"
          details={['Strategy', 'Transformation', 'Operations', 'M&A / Strategic Finance']}
        />
        <LearnMoreItem
          circleGraphic={
            <CircleGraphic className="w-12 h-12 mx-auto">
              <CircleGraphic.Quarter rotate={-90} color="green-500" />
              <CircleGraphic.Quarter rotate={0} color="hpblack" x={2} />
              <CircleGraphic.Quarter rotate={180} color="hpblack" y={2} />
              <CircleGraphic.Quarter rotate={90} color="green-900" x={2} y={2} />
            </CircleGraphic>
          }
          title="Private Equity"
          details={['Investing', 'Value Creation / Portfolio Ops', 'Portfolio Company roles', 'Investor Relations']}
        />
      </div>
    </div>
  );
};

export default StrategicRoles;
