import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CandidateHeroBanner from './elements/CandidateHeroBanner';
import CompaniesCarousel from '../elements/CompaniesCarousel';
import LearnMore from './elements/LearnMore';
import TestimonialCarousel from './elements/TestimonialCarousel';
import WeHaveBeenThere from './elements/WeHaveBeenThere';
import StayInformed from './elements/StayInformed';
import FeaturedPosts from './elements/FeaturedPosts';
import { BuildCareer } from '#components/Index';
import { ReactComponent as BainCompanyLogo } from '#assets/homepage/logo/bainCompanyGrey.svg';
import { ReactComponent as CoinbaseLogo } from '#assets/homepage/logo/coinbaseGrey.svg';
import { ReactComponent as DeliverooLogo } from '#assets/homepage/logo/deliverooGrey.svg';
import { ReactComponent as DellLogo } from '#assets/homepage/logo/dellGrey.svg';
import { ReactComponent as AmazonLogo } from '#assets/homepage/logo/amazon.svg';
import { ReactComponent as AmexLogo } from '#assets/homepage/logo/amex.svg';
import { ReactComponent as DeloitteLogo } from '#assets/homepage/logo/deloitte.svg';
import { ReactComponent as GoogleLogo } from '#assets/homepage/logo/google.svg';
import { ReactComponent as JpmorganLogo } from '#assets/homepage/logo/jpmorgan.svg';
import { ReactComponent as LekLogo } from '#assets/homepage/logo/lek.svg';
import { ReactComponent as MckinseyLogo } from '#assets/homepage/logo/mckinsey.svg';
import { ReactComponent as N26Logo } from '#assets/homepage/logo/n26.svg';
import { ReactComponent as RothschildLogo } from '#assets/homepage/logo/rothschild.svg';
import { ReactComponent as SiemensLogo } from '#assets/homepage/logo/siemens.svg';
import { ReactComponent as UnileverLogo } from '#assets/homepage/logo/unilever.svg';
import { ReactComponent as MicrosoftLogo } from '#assets/homepage/logo/microsoft.svg';
import { ReactComponent as ShellLogo } from '#assets/homepage/logo/shell.svg';

const CandidateIndex: FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Hiperpool - For Professionals</title>
      </Helmet>
      <CandidateHeroBanner />
      <StayInformed />
      <CompaniesCarousel
        title="Hiperpool members work at..."
        logoList={[
          <BainCompanyLogo aria-label="Bain and Company" key="Bain and Company" />,
          <SiemensLogo aria-label="Siemens" key="Siemens" />,
          <DeloitteLogo aria-label="Deloitte" key="Deloitte" />,
          <JpmorganLogo aria-label="J P Morgan" key="J P Morgan" />,
          <MckinseyLogo aria-label="McKinset and Company" key="McKinset and Company" />,
          <DellLogo aria-label="Dell" key="Dell" />,
          <AmazonLogo aria-label="Amazon" key="Amazon" />,
          <RothschildLogo aria-label="Rothschild and Co" key="Rothschild and Co" />,
          <LekLogo aria-label="Lek" key="Lek" />,
          <UnileverLogo aria-label="Unilever" key="Unilever" />,
          <CoinbaseLogo aria-label="Coinbase" key="Coinbase" />,
          <MicrosoftLogo aria-label="Microsoft" key="Microsoft" />,
          <N26Logo aria-label="N 26" key="N 26" />,
          <AmexLogo aria-label="American Express" key="American Express" />,
          <ShellLogo aria-label="Shell" key="Shell" />,
          <GoogleLogo aria-label="Google" key="Google" />,
          <DeliverooLogo aria-label="Deliveroo" key="Deliveroo" />,
        ]}
      />
      <LearnMore />
      <TestimonialCarousel />
      <WeHaveBeenThere />
      <FeaturedPosts />
      <BuildCareer />
    </HelmetProvider>
  );
};

export default CandidateIndex;
